import { mapGetters } from 'vuex';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import planhatMixin from '../mixins/planhat';
import notifications from '@shared/mixins/notifications';
import jwtDecode from 'jwt-decode';
import {
  getTokenOnboardee,
  getTalmundoTokenFromAdminToken,
  getUserMfaConfigurations,
  getTokenMfa,
  deleteUserMfaConfiguration,
  getTokenTemplate,
  getTalmundoTokenFromTalentechToken
} from '../http/authentication';
import { updateLanguage } from '@shared/translations';
import { getHostName, log, SEVERITY } from '@/utils/logging';

export default {
  mixins: [notifications, planhatMixin],
  computed: {
    ...mapGetters('settings', ['company', 'style']),
    ...mapGetters('auth', ['templateId']),
    authStyle() {
      if (isObject(this.style.loginBackground)) {
        return {
          'background-image': 'url(' + this.style.loginBackground.url + ') !important',
          'background-size': 'cover !important',
          'background-position': 'center center',
          'background-repeat': 'no-repeat',
          'background-attachment': 'fixed'
        };
      } else {
        return this.style.loginBackground && this.style.loginBackground.indexOf('url') === 0
          ? {
              'background-image': this.style.loginBackground + ' !important',
              'background-size': 'cover !important',
              'background-position': 'center center',
              'background-repeat': 'no-repeat',
              'background-attachment': 'fixed'
            }
          : {
              background: this.style.loginBackground + ' !important'
            };
      }
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        language: null,
        subdomain: this.$store.getters['settings/subdomain']
      },

      checkedFilters: false,
      remember: true,
      hideLogin: false,
      onboardings: []
    };
  },
  methods: {
    login() {
      this.loading = true;
      getTokenOnboardee(this.user).then(
        (response) => {
          this.setTokenAndUserData(response.data.access_token, response.data.refresh_token, response.data.expires_in);
        },
        (error) => {
          const errorCode = JSON.parse(error?.request?.response)?.error;
          const parsedError = error?.response?.data;
          if (parsedError) {
            if (parsedError.error === 'mfa_required') {
              const userId = jwtDecode(error.response.data.access_token).sub;
              this.$store.dispatch('auth/setToken', {
                token: error.response.data.access_token,
                remember: true
              });

              getUserMfaConfigurations(userId).then((responseUserConfigs) => {
                const confirmedMfa = responseUserConfigs.data.availableProviders[0]?.isConfirmed;

                if (responseUserConfigs.data.availableProviders.length <= 0 && !confirmedMfa) {
                  // Setup 2fa no valid configs onboardee
                  this.goToTwoFactorSetupPage(error.response.data.access_token, false);
                } else if (responseUserConfigs.data.availableProviders.length > 0 && !confirmedMfa) {
                  // invalid config first delete 2fa unconfirmed setup to setup new again.
                  deleteUserMfaConfiguration(userId, responseUserConfigs.data.availableProviders[0].id).then(() =>
                    this.goToTwoFactorSetupPage(error.response.data.access_token, false)
                  );
                } else {
                  // valid config do 2fa login
                  const twoFactorToken = error.response.data.access_token;
                  if (this.authCode) {
                    return this.twoFactorLogin(this.authCode, twoFactorToken);
                  }
                  this.$prompt(this.$t('settings.twoFactorAuthentication'), {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    inputPattern: /^([0-9][0-9][0-9][0-9][0-9][0-9])/,
                    inputErrorMessage: this.$t('settings.twoFactorAuthenticationErrorInput'),
                    inputPlaceholder: this.$t('settings.twoFactorPlaceholder')
                  })
                    .then((confirm) => {
                      this.twoFactorLogin(this.user, { token: twoFactorToken, code: confirm.value, type: 1 });
                      /**
                       * @TODO enable planhat
                       */
                      // this.setPlanhatData(response.data);
                    })
                    .catch((showPrompt) => {
                      if (showPrompt == 'cancel') {
                        this.loading = false;
                      } else {
                        this.showErrorNotification(showPrompt);
                      }
                    });
                }
              });

              return;
            }

            if (parsedError.error === 'invalid_user_or_password') {
              parsedError.error = this.$t('notifications.usernamePasswordNotFound');
            }

            if (parsedError.error === 'user_password_expired') {
              parsedError.error = this.$t('notifications.passwordExpired');
              const redirect = `/change-password?RequestString=${parsedError.password_change_id}`;
              this.$router.push(redirect);
              this.showErrorNotification(parsedError);
              return;
            }
          }
          if (errorCode) {
            this.$notify({
              type: 'error',
              message: this.$t(`login.${errorCode}`),
              position: 'bottom-right'
            });
          } else {
            this.showErrorNotification(parsedError);
          }
          this.loading = false;
        }
      );
    },
    setTokenAndUserData(token, refresh_token, expires_in) {
      this.$store.dispatch('auth/setToken', {
        token,
        remember: true,
        refresh_token,
        expires_in
      });

      this.$http.get('/v2/users/data').then((response) => {
        let user = this.createUserWithTokenAndLanguage(token, response.data.language);
        user = { ...response.data, ...user };
        this.onboard(user);
        this.setDefaultLanguage();
        this.loading = false;
      });
    },
    loginWithTalentechToken(talentechIDToken) {
      getTalmundoTokenFromTalentechToken(talentechIDToken, this.company.id).then(
        (response) => {
          this.setTokenAndUserData(response.data.access_token, response.data.refresh_token, response.data.expires_in);
        },
        () => {
          this.showErrorNotification(this.$t('notifications.loginError'));
          this.loading = false;
          this.hideLogin = false;
        }
      );
    },
    loginWithAdminToken(tokenForAdminLogin) {
      getTalmundoTokenFromAdminToken(tokenForAdminLogin, this.company.id).then(
        (response) => {
          const decodedToken = jwtDecode(response.data.access_token);
          if (decodedToken.template_id) {
            this.$store.dispatch('auth/setTemplateId', decodedToken.template_id);
            this.setTokenAndUserData(response.data.access_token, response.data.refresh_token, response.data.expires_in);
          } else {
            console.error('No TemplateId in token');
          }
        },
        () => {
          this.showErrorNotification(this.$t('notifications.loginError'));
          this.loading = false;
          this.hideLogin = false;
        }
      );
    },
    goToTwoFactorSetupPage(token, forAdmin) {
      this.$store.dispatch('auth/setTwoFactorSetup', {
        token,
        email: this.user.email || this.user.username,
        password: this.user.password,
        forAdmin
      });

      if (this.$route.query.redirect_to) {
        this.$router.push({ path: '/two-factor-setup?redirect_to=' + this.$route.query.redirect_to });
      } else {
        this.$router.push({ path: '/two-factor-setup' });
      }
    },
    async selectOnboarding(onboarding) {
      if (this.$refs['template-modal']) {
        this.$refs['template-modal'].close();
      }
      getTokenTemplate(window.localStorage.getItem('onboarding_token'), onboarding.templateId).then((result) => {
        onboarding.template.token = result.data.access_token;
        this.setToken(onboarding).then(() => {
          this.setPageName(onboarding);
          this.setChatbotData(onboarding);
          this.setLanguages(onboarding);
          this.showLoader = true;
          this.$store.dispatch('auth/setTemplateId', onboarding.templateId);

          this.updateProfile(this.$store.state.auth.user).then(() => {
            this.loginSuccess();
            setTimeout(() => {
              if (this.$store.state.auth.redirectPath) {
                this.$router.push(this.$store.state.auth.redirectPath);
              } else {
                this.$router.push({ path: '/dashboard' });
              }
            }, 200);
          });
        });
      });
    },
    setUser(user) {
      if (user.language) {
        window.localStorage.setItem('onboarding_defaultLanguage', user.language);
        updateLanguage(user.language);
      }
      return this.$store.dispatch('auth/setUser', {
        user: user,
        remember: true
      });
    },
    setPlanhatData(onboarding) {
      const { planhatTenantTokens, talentechTenantId } = onboarding;
      if (planhatTenantTokens && talentechTenantId !== null) {
        this.$store.dispatch('auth/setPlanhatData', {
          planhatTenantTokens,
          talentechTenantId
        });
      }
    },
    setChatbotData(onboarding) {
      const { chatbotEnabled } = onboarding.template;
      this.$store.dispatch('auth/setChatbotData', {
        chatbotEnabled,
        remember: true
      });
    },
    setPageName(onboarding) {
      document.title = onboarding.template.pageName;
      window.localStorage.onboardingPageName = onboarding.template.pageName;
    },
    async setToken(onboarding) {
      return new Promise((resolve) => {
        this.$store.dispatch('auth/setToken', {
          token: onboarding.template.token,
          remember: true
        });

        if (this.$store.state.auth.user.isAdmin) {
          var date = new Date(new Date().setDate(new Date().getDate() + 30)).toJSON(); // moment().add(30, "days").toJSON();

          this.$http
            .put('/demo/onboarding/startdate', {
              date
            })
            .then(() => {})
            .finally(() => {
              resolve();
            });
        } else {
          resolve();
        }
      });
    },
    setLanguages(onboarding) {
      this.$store.dispatch('settings/setAvailableLanguages', {
        languages: onboarding.template.languages
      });
    },
    setDefaultLanguage() {
      const localStorageUser = window.localStorage.getItem('onboarding_user');
      const language = localStorageUser
        ? JSON.parse(localStorageUser).language
        : window.localStorage.getItem('onboarding_defaultLanguage');

      this.$http.post('/v2/users/language', { languageCode: language });
    },
    loginSuccess() {
      this.hideLogin = false;
      this.$notify({
        type: 'success',
        message: this.$t('notifications.loginSuccess'),
        position: 'bottom-right'
      });

      this.track('tal_login_attempt_succeeded');
      const messageTemplateValues = [
        String(JSON.parse(window.localStorage.getItem('onboarding_user')).id),
        getHostName()
      ];
      log({
        messageTemplate: 'User logged in userid: {userId} company: {host}',
        messageTemplateValues,
        severity: SEVERITY.INFORMATION
      });
      this.showSuccessNotification(this.$t('notifications.loginSuccess'));
    },
    twoFactorLogin(user, mfa) {
      getTokenMfa(user, mfa).then(
        (twoFactorLoginResponse) => {
          this.$store.dispatch('auth/setToken', {
            token: twoFactorLoginResponse.data.access_token,
            remember: true
          });

          this.$http.get('/v2/users/data').then((response) => {
            let user = this.createUserWithTokenAndLanguage(
              twoFactorLoginResponse.data.access_token,
              response.data.language
            );
            user = { ...response.data, ...user };
            this.onboard(user);
            this.setDefaultLanguage();
          });
        },
        (error) => {
          if (error?.response?.data?.error === 'invalid_mfa_code') {
            error.error = this.$t('login.invalid_mfa_code');
          }
          this.loading = false;
          this.hideLogin = false;
          this.showErrorNotification(error);
        }
      );
    },
    async onboard(user) {
      this.setUser(user);
      this.checkSelectOnboardings(user);
    },
    checkSelectOnboardings(user) {
      // get uniques
      user.onboardings = uniqBy(user.onboardings, (onboarding) => onboarding.templateId);

      if (user && user.onboardings && this.templateId) {
        const onboarding = user.onboardings.find((onboarding) => onboarding.templateId === Number(this.templateId));
        if (onboarding) {
          this.selectOnboarding(onboarding);
        } else {
          console.error('No onboarding found for templateId');
        }
      } else if (user && user.onboardings && user.onboardings?.length > 1) {
        this.onboardings = user.onboardings;
        this.$refs['template-modal'].open();
      } else if (user.onboardings?.length) {
        this.selectOnboarding(user.onboardings[0]);
      } else {
        this.hideLogin = false;
        this.showInfoNotification(this.$t('notifications.noOnboardingIds'));
      }
    },
    updateProfile(user) {
      return new Promise((resolve, reject) => {
        this.$http
          .put('/profile', { ...this.user, ...omit(user, 'email') })
          .then(() => {
            resolve();
          })
          .catch(resolve);
      });
    },
    createUserWithTokenAndLanguage(token, userDefaultLanguage) {
      const localStorageUser = window.localStorage.getItem('onboarding_user');
      let user = {
        access_token: token,
        languageId: localStorageUser
          ? JSON.parse(localStorageUser).languageId
          : localStorageUser
          ? JSON.parse(localStorageUser).languageId
          : null,
        language: localStorageUser
          ? JSON.parse(localStorageUser).language
          : localStorageUser
          ? JSON.parse(localStorageUser).language
          : null
      };

      if (!user.language) {
        user.language = userDefaultLanguage;
      }

      if (!user.languageId) {
        user.languageId = this.company.languages.find((language) => language.localeCode === user.language).languageId;
      }

      return user;
    }
  }
};
